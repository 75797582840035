import idLang from "./entries/id-IN";
import {addLocaleData} from "react-intl";

const AppLocale = {
  id: idLang
};

addLocaleData(AppLocale.id.data);

export default AppLocale;
