import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET,
  SHOW_MESSAGE
} from "../../constants/ActionTypes";
import axios from 'util/Api'

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const userSignUp = ({email, password, name}) => {
  console.log(email, password);
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('auth/register', {
        email: email,
        password: password,
        name: name
      }
    ).then(({data}) => {
      if (data.result) {
        axios.defaults.headers.common['access-token'] = "Bearer " + data.token.access_token;
        // localStorage.setItem("token", JSON.stringify(data.token.access_token));
        // dispatch({type: FETCH_SUCCESS, payload:'Success'});
        // dispatch({type: USER_TOKEN_SET, payload: data.token.access_token});
        // dispatch({type: USER_DATA, payload: data.user});
      } else {
        dispatch({type: FETCH_ERROR, payload: "Network Error"});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const userSignIn = ({username, password}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    
    //bypass axios
    // localStorage.setItem("token", JSON.stringify('login'));
    // dispatch({type: FETCH_SUCCESS});
    // dispatch({type: USER_TOKEN_SET, payload: 'login'});

    axios.post('bo/login', {
        email: username,
        pwd: password,
      }
    ).then(({data}) => {
      console.log("userSignIn: ", data);
      if (data.sta && data.stacod === 200) {
        localStorage.setItem("tkn", data.data.tkn);
        localStorage.setItem("tknref", data.data.tknref);
        localStorage.setItem("email", username)
        axios.defaults.headers.common['Authorization'] = "Bearer " + data.data.tkn;
        dispatch({type: FETCH_SUCCESS, payload:'Success'});
        dispatch({type: USER_TOKEN_SET, payload: {tkn:data.data.tkn,tkref:data.data.tknref}});
      } else {
        dispatch({type: FETCH_ERROR,payload:data.msg});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
    });
    
  }
};

export const getUser = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('bo/user/auth',{
      "email": localStorage.getItem("email")
    }
    ).then(({data}) => {
      console.log("userSignIn: ", data);
      if (data.sta && data.stacod === 200) {
        console.log('getUser Okee')
        // dispatch({type: FETCH_SUCCESS, payload: data.data});
        dispatch({type: USER_DATA, payload: data.data});
      } else {
        console.log('getUser NOT Okee')
        // dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};


export const userSignOut = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    setTimeout(() => {
      axios.defaults.headers.common['Authorization'] = null;
      dispatch({type: USER_DATA, payload: ''});
      localStorage.removeItem("tkn");
      localStorage.removeItem("tknref");
      dispatch({type: FETCH_SUCCESS});
      dispatch({type: USER_DATA, payload: {}});
      dispatch({type: SIGNOUT_USER_SUCCESS});
    }, 2000);
  }
};
