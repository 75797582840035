import {INIT_URL, SIGNOUT_USER_SUCCESS, USER_DATA, USER_TOKEN_SET} from "../../constants/ActionTypes";

const INIT_STATE = {
  tkn: localStorage.getItem('tkn'),
  tknref: localStorage.getItem('tknref'),
  initURL: '',
  authUser:{}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {


    case INIT_URL: {
      return {...state, initURL: action.payload};
    }

    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        tkn: null,
        tknref: null,
        initURL: ''
      }
    }

    case USER_DATA: {
      return {
        ...state,
        authUser: action.payload,
      };
    }

    case USER_TOKEN_SET: {
      return {
        ...state,
        tkn: action.payload.tkn,
        tknref: action.payload.tknref,
      };
    }

    default:
      return state;
  }
}
