import axios from 'axios';
import urlConfig from './urlGet';

const api = axios.create({
  baseURL: urlConfig.urlBase,
  headers: {
    'Content-Type': 'application/json',
  }
});

api.interceptors.response.use(
  (response) => {
    if(response.data.stacod && response.data.msg) {
      if(response.data.stacod === 200 && response.data.msg === "expired token") {
        api.defaults.headers.common['Authorization'] = null;
        localStorage.removeItem("tkn");
        localStorage.removeItem("tknref");
        window.location.href = '/signin';
      }
    }
    return response;
  }
);

export default api;
