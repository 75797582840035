import React, {Component} from "react";
import {connect} from "react-redux";
import {Avatar, Popover, Divider, Icon} from "antd";
import {userSignOut} from "appRedux/actions/Auth";
import urlConfig from './../../util/urlGet';
import {Link, withRouter} from "react-router-dom";

class UserInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
  }

  render() {
    const {authUser} = this.props;
    const userMenuOptions = (
      <ul className="gx-user-popover">
        <Link to="/profile">
          <li onClick={() => this.setState({visible: false})}>
              My Profile
          </li>
        </Link>
        <Divider/>
        <li onClick={() => this.props.userSignOut()}>
          <Icon type="poweroff"/> Logout
        </li>
      </ul>
    );

    return (
      <Popover overlayClassName="gx-popover-horizantal" onClick={() => this.setState({visible: true})} visible={this.state.visible} placement="bottomRight" content={userMenuOptions}
               trigger="click">
        <Avatar src={authUser.pict != '' ? urlConfig.urlProfile + authUser.pict : require("assets/images/kovre_small.png")}
                className="gx-avatar gx-pointer" alt=""/>
      </Popover>
    )

  }
}

const mapStateToProps = ({auth}) => {
  const {authUser} = auth;
  return {authUser}
};

export default connect(mapStateToProps, {userSignOut})(UserInfo);
