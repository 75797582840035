import React from "react";
import {Route, Switch} from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const App = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}dashboard`} component={asyncComponent(() => import('./Dashboard'))}/>
      <Route path={`${match.url}master`} component={asyncComponent(() => import('./Master'))}/>
      <Route path={`${match.url}expense`} component={asyncComponent(() => import('./Expense'))}/>
      <Route path={`${match.url}utility`} component={asyncComponent(() => import('./Utility'))}/>
      <Route path={`${match.url}transaction`} component={asyncComponent(() => import('./Transaction'))}/>
      <Route path={`${match.url}profile`} component={asyncComponent(() => import('./Profile'))}/>
      <Route path={`${match.url}report`} component={asyncComponent(() => import('./Report'))}/>
    </Switch>
  </div>
);

export default App;
