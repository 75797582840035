import React, {Component} from "react";
import {connect} from "react-redux";
import {Avatar, Popover, Divider, Icon} from "antd";
import {userSignOut} from "appRedux/actions/Auth";
import urlConfig from './../../util/urlGet';
import {Link} from "react-router-dom";

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
  }
  render() {
    const {authUser} = this.props;
    const userMenuOptions = (
      <ul className="gx-user-popover">
        <Link to="/profile">
          <li onClick={() => this.setState({visible: false})}>
              My Profile
          </li>
        </Link>
        <Divider/>
        <li onClick={() => this.props.userSignOut()}>
          <Icon type="poweroff"/> Logout
        </li>
      </ul>
    );

    return (

      <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
        <Popover placement="bottomRight" onClick={() => this.setState({visible: true})} visible={this.state.visible} content={userMenuOptions} trigger="click">
          {
            authUser ?
            <Avatar src={authUser.pict != '' ? urlConfig.urlProfile + authUser.pict : require("assets/images/kovre_small.png")}
                    className="gx-size-40 gx-pointer gx-mr-3" alt=""/> :
            <Avatar src={require("assets/images/kovre_small.png")}
                    className="gx-size-40 gx-pointer gx-mr-3" alt=""/>
          }
          <span className="gx-avatar-name">{authUser ? authUser.name : "Loading.."}<i
            className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/></span>
        </Popover>
      </div>

    )

  }
}

const mapStateToProps = ({auth}) => {
  const {authUser} = auth;
  return {authUser}
};
  
export default connect(mapStateToProps, {userSignOut})(UserProfile);
