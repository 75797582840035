import React, {Component} from "react";
import {connect} from "react-redux";
import {Menu} from "antd";
import {Link} from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";

import Auxiliary from "util/Auxiliary";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

class SidebarContent extends Component {

  getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  render() {
    const {themeType, navStyle, pathname} = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];
    return (<Auxiliary>

        <SidebarLogo/>
        <div className="gx-sidebar-content">
          <div className={`gx-sidebar-notifications ${this.getNoHeaderClass(navStyle)}`}>
            <UserProfile/>
            {/* <AppsNavigation/> */}
          </div>
          <CustomScrollbars className="gx-layout-sider-scrollbar">
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
              mode="inline">

              <MenuItemGroup key="main" className="gx-menu-group" title={<IntlMessages id="sidebar.main"/>}>

                <Menu.Item key="dashboard">
                  <Link to="/dashboard"><i className="icon icon-dasbhoard"/>
                  <IntlMessages id="sidebar.dashboard"/></Link>
                </Menu.Item>

                {/* <Menu.Item key="main/widgets">
                  <Link to="/main/widgets"><i className="icon icon-widgets"/>
                    <IntlMessages id="sidebar.widgets"/></Link>
                </Menu.Item>

                <Menu.Item key="main/metrics">
                  <Link to="/main/metrics"><i className="icon icon-apps"/>
                    <IntlMessages id="sidebar.metrics"/></Link>
                </Menu.Item> */}

              </MenuItemGroup>

              <MenuItemGroup key="master" className="gx-menu-group" title={<IntlMessages id="sidebar.master"/>}>
                
                {/* <Menu.Item key="master/province">
                  <Link to="/master/province"><i className="icon icon-apps"/>
                    <IntlMessages id="sidebar.master.province"/></Link>
                </Menu.Item> */}
                <Menu.Item key="master/variant">
                  <Link to="/master/variant"><i className="icon icon-apps"/>
                    <IntlMessages id="sidebar.master.variant"/></Link>
                </Menu.Item>
                <Menu.Item key="master/unit">
                  <Link to="/master/unit"><i className="icon icon-apps"/>
                    <IntlMessages id="sidebar.master.unit"/></Link>
                </Menu.Item>
                <Menu.Item key="master/payment">
                  <Link to="/master/payment"><i className="icon icon-apps"/>
                    <IntlMessages id="sidebar.master.payment"/></Link>
                </Menu.Item>
                <Menu.Item key="master/category">
                  <Link to="/master/category"><i className="icon icon-apps"/>
                    <IntlMessages id="sidebar.master.category"/></Link>
                </Menu.Item>
                <Menu.Item key="master/user">
                  <Link to="/master/user"><i className="icon icon-apps"/>
                    <IntlMessages id="sidebar.master.user"/></Link>
                </Menu.Item>
                <Menu.Item key="master/cust">
                  <Link to="/master/cust"><i className="icon icon-apps"/>
                    <IntlMessages id="sidebar.master.cust"/></Link>
                </Menu.Item>
                <Menu.Item key="master/product">
                  <Link to="/master/product"><i className="icon icon-apps"/>
                    <IntlMessages id="sidebar.master.product"/></Link>
                </Menu.Item>
                <Menu.Item key="master/banner">
                  <Link to="/master/banner"><i className="icon icon-apps"/>
                    <IntlMessages id="sidebar.master.banner"/></Link>
                </Menu.Item>
                <Menu.Item key="master/promotion">
                  <Link to="/master/promotion"><i className="icon icon-apps"/>
                    <IntlMessages id="sidebar.master.promotion"/></Link>
                </Menu.Item>

              </MenuItemGroup>


              <MenuItemGroup key="transaction" className="gx-menu-group" title={<IntlMessages id="sidebar.transaction"/>}>
                
                <Menu.Item key="transaction/order">
                  <Link to="/transaction/order"><i className="icon icon-apps"/>
                    <IntlMessages id="sidebar.transaction.order"/></Link>
                </Menu.Item>
                
              </MenuItemGroup>

              <MenuItemGroup key="expense" className="gx-menu-group" title={<IntlMessages id="sidebar.expense"/>}>
                
                
                <Menu.Item key="expense/expense">
                  <Link to="/expense/expense"><i className="icon icon-apps"/>
                    <IntlMessages id="sidebar.expense.expense"/></Link>
                </Menu.Item>

                <Menu.Item key="expense/stock">
                  <Link to="/expense/stock"><i className="icon icon-apps"/>
                    <IntlMessages id="sidebar.expense.stock"/></Link>
                </Menu.Item>
                

              </MenuItemGroup>

              <MenuItemGroup key="utility" className="gx-menu-group" title={<IntlMessages id="sidebar.utility"/>}>
                
                
                <Menu.Item key="utility/coupon">
                  <Link to="/utility/coupon"><i className="icon icon-apps"/>
                    <IntlMessages id="sidebar.utility.coupon"/></Link>
                </Menu.Item>
                <Menu.Item key="utility/disc">
                  <Link to="/utility/disc"><i className="icon icon-apps"/>
                    <IntlMessages id="sidebar.utility.disc"/></Link>
                </Menu.Item>
                <Menu.Item key="utility/message">
                  <Link to="/utility/message"><i className="icon icon-apps"/>
                    <IntlMessages id="sidebar.utility.message"/></Link>
                </Menu.Item>
                <Menu.Item key="utility/suscriber">
                  <Link to="/utility/suscriber"><i className="icon icon-apps"/>
                    <IntlMessages id="sidebar.utility.suscriber"/></Link>
                </Menu.Item>
                <Menu.Item key="utility/suscribercontent">
                  <Link to="/utility/suscribercontent"><i className="icon icon-apps"/>
                    <IntlMessages id="sidebar.utility.suscribercontent"/></Link>
                </Menu.Item>
                

              </MenuItemGroup>

              <MenuItemGroup key="report" className="gx-menu-group" title={<IntlMessages id="sidebar.report"/>}>
                
                
                <Menu.Item key="report/custreport">
                  <Link to="/report/custreport"><i className="icon icon-apps"/>
                    <IntlMessages id="sidebar.report.custreport"/></Link>
                </Menu.Item>
                <Menu.Item key="report/transreport">
                  <Link to="/report/transreport"><i className="icon icon-apps"/>
                    <IntlMessages id="sidebar.report.transreport"/></Link>
                </Menu.Item>
                <Menu.Item key="report/shipreport">
                  <Link to="/report/shipreport"><i className="icon icon-apps"/>
                    <IntlMessages id="sidebar.report.shipreport"/></Link>
                </Menu.Item>
                <Menu.Item key="report/expreport">
                  <Link to="/report/expreport"><i className="icon icon-apps"/>
                    <IntlMessages id="sidebar.report.expreport"/></Link>
                </Menu.Item>
                
                

              </MenuItemGroup>
              {/* <MenuItemGroup key="report" className="gx-menu-group" title={<IntlMessages id="sidebar.report"/>}>
                
                <Menu.Item key="main/metrics11">
                  <Link to="/main/metrics"><i className="icon icon-apps"/>
                    <IntlMessages id="sidebar.metrics"/></Link>
                </Menu.Item>

              </MenuItemGroup> */}
            
            </Menu>
          </CustomScrollbars>
        </div>
      </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({settings}) => {
  const {navStyle, themeType, locale, pathname} = settings;
  return {navStyle, themeType, locale, pathname}
};
export default connect(mapStateToProps)(SidebarContent);

